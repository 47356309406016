html {
    scroll-behavior: smooth;
    font-family: Lato, sans-serif;
    overflow-x: hidden;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
}